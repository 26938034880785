import { useState } from 'react'
import { Input, Button, Grid, GridItem } from '@postenbring/hedwig-react'
import './track.scss'

const DEFAULT_TRACKING_URL = 'https://tracking.bring.com/tracking/'
const START_HTTPS_RX = /^[\s\/]*(https?:\/\/)?/i
const END_SLASH_RX = /[\s\/]*$/

// Use default URL if not set, enforce starting with 'https://' and ending with one slash
const normalizeUrl = (url) => {
    if (!(url || "").trim()) {
        url = DEFAULT_TRACKING_URL
    }
    return url.replace(START_HTTPS_RX, 'https://').replace(END_SLASH_RX, '/')
}

export const Track = ({
    label,
    formLabelColor,
    url,
    buttonLabel,
    placeholder,
    isEnglish,
    ariaLabel
}) => {

    url = normalizeUrl(url);

    const [traceUrl, setTraceUrl] = useState(url)
    const [inputValue, setInputValue] = useState('');
    const updateTraceUrl = (value) => {
        setInputValue(value);
        setTraceUrl(url + value)
    }

    return (
        <>
            <form
                action={traceUrl}
                method='get'
                id='traceForm'
                className={formLabelColor}
                onSubmit={(e) => {
                    e.preventDefault();
                    const targetUrl = new URL(url);
                    targetUrl.pathname += encodeURIComponent(inputValue.trim());
                    if (isEnglish) {
                        targetUrl.searchParams.set('lang', 'en');
                    }
                    window.location.href = targetUrl.toString();
                }}
            >
                {isEnglish && <input type='hidden' name='lang' value='en' />}

                <div className='kp-trace-field'>          
                       
                                <Input id='tracking-number' label={label} type='text' onChange={(e) => updateTraceUrl(e.target.value)} placeholder={placeholder} aria-label={ariaLabel} />
                            
                                <Button type='submit' size='large'>{buttonLabel}</Button>
                           
                    </div>
            </form>
        </>
    )
}
