import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentsAlt } from '@fortawesome/pro-solid-svg-icons'
import { loadScript } from '/lib/load-script'
import { Button } from '@postenbring/hedwig-react'

export const ChatButton = ({ text }) => {
    const handleClick = () => {
        /**
         * The variable 'kindlyScriptAttribute' and the function
         * 'callClickEventForChatbotButton' are implicitly loaded
         * by kp-app-posten and kp-app-bring on each page where
         * the chatbot is enabled.
         * https://github.com/bring/kp-app-posten/blob/master/src/main/resources/assets/js/init-kindly-chatbot.js
         */

        loadScript(
            'https://chat.kindlycdn.com/kindly-chat.js',
            'kindly-chat',
            kindlyScriptAttributes
        )
            .then(callClickEventForChatbotButton)
            .catch(function (error) {
                console.error('Error loading chatbot script:', error)
            })
    }

    return (
            <Button onClick={handleClick} className='hds-mb-20-24'>
                <FontAwesomeIcon icon={faCommentsAlt} />
                &nbsp;{text}
            </Button>
    )
}
