import React, { useState } from 'react'
import { Button, Input, Box, Text, UnorderedList, Message } from '@postenbring/hedwig-react'
import './delivery-days-pip.scss'

export const DeliveryDaysPiP = ({ title, description, postCodeLabel, serviceUrl, buttonTextLoading, inputAriaLabel, language, placeholder, buttonText, postCodeErrorMsg, deliveryDateText, addressText, workingDaysText, deliveryDaysText, technicalDescription, postCodeEmptyInput 
    }) => {
    const [postalCode, setPostalCode] = useState('')
    const [workingDays, setWorkingDays] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [errorInput, setInputError] = useState(null)
    const [postalPlace, setPostalPlace] = useState(null)
    const [formattedExpectedDeliveryDate, setFormattedExpectedDeliveryDate] = useState(null)
    const [alternativeDeliveryDates, setAlternativeDeliveryDates] = useState([])

    const handleInputChange = (e) => {
        let value = e.target.value
        
        if (!/^\d{0,4}$/.test(value)) return

        setPostalCode(value)
        setWorkingDays(null)
        setPostalPlace(null)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            fetchDeliveryTime()
        }
    }

    const fetchDeliveryTime = async () => {
        if (!/^\d{4}$/.test(postalCode)) {
            setInputError(postCodeEmptyInput)
            //setError(postCodeErrorMsg)
            setError(null)
            setWorkingDays(null)
            return
        }

        setLoading(true)
        setError(null)
        setInputError(null)

        try {

            const postalResponse = await fetch(`https://adressesok.posten.no/api/v1/addresses.json?address=postnummer:${postalCode}`)
            const postalData = await postalResponse.json()
            const postalPlace = postalData.addresses?.[0]?.city || null

            if (!postalPlace) {
                setInputError(postCodeEmptyInput)
                setLoading(false)
                return
            }

            setPostalPlace(postalPlace)

            const response = await fetch(serviceUrl + '?postalCode=' + postalCode)
            const data = await response.json()

            if (data.workingDays) {
                setWorkingDays(data.workingDays)
                setFormattedExpectedDeliveryDate(data.formattedExpectedDeliveryDate)
                setAlternativeDeliveryDates(data.alternativeDeliveryDates)
            } else {
                setError(postCodeErrorMsg)
            }
        } catch (err) {
            setError(postCodeErrorMsg) 
        } finally {
            setLoading(false)
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return ''
    
        // Convert "DD.MM.YYYY" to "YYYY-MM-DD"
        const [day, month, year] = dateString.split('.')
        const formattedDateString = `${year}-${month}-${day}`
    
        const date = new Date(formattedDateString)
        if (isNaN(date.getTime())) return 'Invalid date'
    
        const locale = language
    
        return new Intl.DateTimeFormat(locale, {
            weekday: 'long',
            day: '2-digit',
            month: 'long'
        }).format(date)
    }

    return (
        <Box>
            <Text as="h3" variant="h3-title">{title}</Text> 
            <Text className="hds-mt-16-20" variant="body-small">{description}</Text>
            <Text className="hds-mt-16-20" variant="technical">{technicalDescription}</Text>

            <form onSubmit={(e) => { e.preventDefault(); fetchDeliveryTime(); }}>
                <Input
                    inputMode="numeric"
                    pattern="\d*"
                    className="hds-mt-16-20"
                    type="tel"
                    aria-label={inputAriaLabel ?? 'Type your input here'}
                    label={postCodeLabel}
                    placeholder={placeholder}
                    onKeyUp={handleKeyUp}
                    onChange={handleInputChange}
                    errorMessage={errorInput ? errorInput || errorInput : ''} 
                    variant='white'
                    value={postalCode}
                    id='pip-input-text'
                    name='postcode'
                    maxLength={4}
                    style={{ width: '183px' }}
                />
                <Button className="hds-mt-8-12" 
                    type='submit'
                    disabled={loading} 
                    variant="primary" 
                    size="medium"
                    >
                    {loading ? buttonTextLoading : buttonText}
                </Button>
            </form>
            {error && <Message variant="warning" className="hds-mt-16-20"><Message.Description>{error}</Message.Description></Message>}
           
            {!error && workingDays !== null && postalPlace && (
            <>
                <Text className="hds-mt-16-20" variant="body-small-title">
                    {addressText} 
                </Text>
                <Text variant="body-small">
                    {postalCode} {postalPlace}
                </Text>
                <Text className="hds-mt-16-20" variant="body-small-title">
                    {deliveryDaysText}
                </Text>
                <Text variant="body-small">
                    {workingDays} {workingDaysText}
                </Text>

                {/* Ensure alternativeDeliveryDates is shown only after workingDays */}
                {alternativeDeliveryDates.length > 0 && formattedExpectedDeliveryDate && (
                    <>
                        <Text className="hds-mt-16-20" variant="body-small-title">
                            {deliveryDateText}
                        </Text>
                        <UnorderedList size="medium" style={{ paddingLeft: '19px' }}>
                            <li><Text variant="body-small">{formatDate(formattedExpectedDeliveryDate)}</Text></li>
                            {alternativeDeliveryDates.map((date, index) => (
                                <li key={index}>
                                    <Text variant="body-small">{formatDate(date)}</Text>
                                </li>
                            ))}
                        </UnorderedList>
                    </>
                )}
            </>
        )}
    </Box>
    )
}